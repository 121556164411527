import _ from "lodash";
import { getPreferredLanguage } from "./localizationManager";

export const IdentityURL = process.env.REACT_APP_IDENTITY_URL;
export const AdminURL = process.env.REACT_APP_ADMIN_URL;
export const ApiURL = process.env.REACT_APP_API_URL;
export const ApiURL_2 = process.env.REACT_APP_API2_URL;
export const ApiURL_3 = process.env.REACT_APP_API3_URL;
export const KibanaURL = process.env.REACT_APP_KIBANA_URL;
export const SchedulerURL = process.env.REACT_APP_SCHEDULER_URL;
export const ECommerceURL = process.env.REACT_APP_ECOMMERCE_URL;

export const PaymentCallbackSuccessUrl = "/paymentCallback/success";
export const PaymentCallbackFailedUrl = "/paymentCallback/failed";

export const IsRTL = _.get(getPreferredLanguage(), 'isRTL', false);

export const DEFAULT_LANGUAGE_CODE = "en";

export default { AdminURL, IdentityURL, ApiURL, ApiURL_2, ApiURL_3, KibanaURL, SchedulerURL, ECommerceURL, IsRTL }