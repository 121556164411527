import moment from './moment';

var cachedObjects = {};

export const DEFAULT_CACHE_EXPIRATION_MIN = 5;
export const DEFAULT_CACHE_LOCK_TIMEOUT_MS = 5000;
export const DEFAULT_CACHE_STORAGE = "local";

function cacheGet(key, storageName = DEFAULT_CACHE_STORAGE) {
    try {
        var temp = null;

        if (cachedObjects.hasOwnProperty(key)) {
            temp = cachedObjects[key];
            if (temp && temp.expires && temp.expires > moment())
                return temp.data;
            else temp = null;
        }

        if (temp == null) {
            temp = getStorage(storageName).getItem(key);
            if (temp != null) {
                try {
                    temp = JSON.parse(temp);
                } catch (e) {
                    console.error(e);
                }
                cacheSet(key, temp, DEFAULT_CACHE_EXPIRATION_MIN, storageName);

                return temp;
            }
        }
    } catch (error) {
        console.error(error);
    }
    return null;
}

function cacheSet(key, object, expirationMin = DEFAULT_CACHE_EXPIRATION_MIN, storageName = DEFAULT_CACHE_STORAGE) {
    try {
        if (key && object) {

            var temp = {
                data: object,
                expires: moment().add(expirationMin, 'm')
            }
            cachedObjects[key] = temp;

            if (typeof object === 'object')
                object = JSON.stringify(object);

            getStorage(storageName).setItem(key, object);
            return true;
        }
    } catch (error) {
        console.error(error);
    }
    return false;
}

function cacheDelete(key, storageName = DEFAULT_CACHE_STORAGE) {
    try {
        if (cachedObjects.hasOwnProperty(key)) {
            cachedObjects[key] = undefined;

            getStorage(storageName).removeItem(key);
            return true;
        }
    } catch (error) {
        console.error(error);
    }
    return false;
}

async function cacheLock(lockKey, storageName = DEFAULT_CACHE_STORAGE) {
    try {
        lockKey += "_lock";

        if (cacheGet(lockKey, storageName) != null)
            await sleep(DEFAULT_CACHE_LOCK_TIMEOUT_MS)

        if (cacheGet(lockKey, storageName) != null) {
            cacheDelete(lockKey, storageName);
            return false;
        }
        else {
            cacheSet(lockKey, "locked", DEFAULT_CACHE_EXPIRATION_MIN, storageName);
            return true
        }
    } catch (error) {
        console.error(error);
    }
    return false;
}

async function cacheUnlock(lockKey, storageName = DEFAULT_CACHE_STORAGE) {
    try {
        lockKey += "_lock";

        cacheDelete(lockKey, storageName);
        return true;
    } catch (error) {
        console.error(error);
    }
    return false;
}

function cacheClear() {
    try {
        for (const key in cachedObjects) {
            if (cachedObjects.hasOwnProperty(key)) {
                localStorage.removeItem(key);
                sessionStorage.removeItem(key);
            }
        }
        cachedObjects = {};

        return true;
    } catch (error) {
        console.error(error);
    }
    return false;
}

function clearCookies() {
    var res = document.cookie;
    var multiple = res.split(";");
    for (var i = 0; i < multiple.length; i++) {
        var key = multiple[i].split("=");
        document.cookie = key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
    }
}

/**
 * Provided storage keys will be restored after clearing site data.
 *
 */
function clearSiteData() {
    var toBeRestored = ["preferredLang"]
    var storeCheckpoint = toBeRestored.map(key => ({ key, value: localStorage.getItem(key) }));

    localStorage.clear();
    sessionStorage.clear();
    clearCookies();
    cacheClear();

    storeCheckpoint.forEach(item => {
        if (item.key && item.value != null)
            localStorage.setItem(item.key, item.value)
    });
}

function getStorage(storageName) {
    switch (storageName) {
        case "local":
            return localStorage;
        case "session":
        default:
            return sessionStorage;
    }
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export { cacheGet, cacheSet, cacheDelete, cacheClear, clearSiteData, clearCookies, cacheLock, cacheUnlock };