import { useEffect } from 'react';

export const useScript = (url: string, async: boolean = false) => {
  useEffect(() => {
    const script = embedScript(url, async);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

export function embedScript(url: string, async: boolean = false) {
  const script = document.createElement('script');

  script.src = url;
  script.async = async;
  // script.defer = true;

  document.body.appendChild(script);

  return script;
};

export const useCSS = (url: string) => {
  useEffect(() => {
    const link = embedCSS(url);

    return () => {
      document.head.removeChild(link);
    }
  }, [url]);
};

export function embedCSS(url: string) {
  const link = document.createElement('link');

  link.href = url;
  link.rel = "stylesheet"
  link.type = "text/css"

  document.head.appendChild(link);

  return link;
};

export function useTitle(title: string) {
  useEffect(() => {
    const prevTitle = document.title
    setTitle(title);
    return () => {
      setTitle(prevTitle);
    }
  }, [title])
}

export function setTitle(title: string) {
  document.title = title;
}