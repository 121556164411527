import React from 'react';
import loadable from '@loadable/component'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useScript } from './embedScript';
import { ToastContainer, Slide, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PaymentCallbackFailedUrl, PaymentCallbackSuccessUrl } from './common/constants';

// const Loading = () => <div className="animated fadeIn pt-3 text-center">{/* getLocalizedText */("LOADING")}</div>;

const loadableOptions = undefined;
/*  {
  fallback: <Loading />,
} */
const Header = loadable(() => import('./components/Header'));
const Footer = loadable(() => import('./components/Footer'));
const NotFound = loadable(() => import('./pages/NotFound'), loadableOptions);
const Home = loadable(() => import('./pages/Home'), loadableOptions);
const AboutUs = loadable(() => import('./pages/AboutUs'), loadableOptions);
const ContactUs = loadable(() => import('./pages/ContactUs'), loadableOptions);
const Demo = loadable(() => import('./pages/Demo'), loadableOptions);
const FAQ = loadable(() => import('./pages/FAQ'), loadableOptions);
const Features = loadable(() => import('./pages/Features'), loadableOptions);
const PricingConfigurator = loadable(() => import('./pages/PricingConfigurator'), loadableOptions);
const PaymentInit = loadable(() => import('./pages/PaymentInit'), loadableOptions);
const PaymentCalback = loadable(() => import('./pages/PaymentCallback'), loadableOptions);
const TermsAndConditions = loadable(() => import('./pages/TermsAndConditions'), loadableOptions);

const toastContainerProps: ToastContainerProps = {
  position: 'bottom-right',
  theme: "colored",
  transition: Slide,
  autoClose: 4000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  limit: 5
  /*  get style() {
     return {
       'bottom-right': { bottom: 40 },
       'bottom-left': { bottom: 40 },
       'bottom-center': { bottom: 40 }
     }[this.position];
   } */
}

function DefaultLayout(props: any) {
  return (
    <>
      <Header />
      <div style={{ minHeight: "63vh" }}>
        {props.children}
      </div>
      <Footer />
    </>
  )
};

function App() {

  useScript(`${process.env.PUBLIC_URL}/js/custom.js`);

  return (
    <>
      {/* preloader */}
      <ToastContainer {...toastContainerProps} />
      <div id="preloader">
        <div id="spinner">
          <img alt="" src="images/preloaders/5.gif" />
        </div>
        <div id="disable-preloader" className="btn btn-default btn-sm"></div>
      </div>
      <Router>
        <Switch>
          <Route exact path="/termsandconditions(.html)?" component={TermsAndConditions} />
          <Route exact path={PaymentCallbackFailedUrl} component={PaymentCalback} />
          <Route exact path="/404" component={NotFound} />
          <Route path="*" render={routeProps => (
            <DefaultLayout {...routeProps}>
              <Switch>
                <Route exact path="/(index.html)?" component={Home} />
                <Route exact path="/aboutus(.html)?" component={AboutUs} />
                <Route exact path="/contactus(.html)?" component={ContactUs} />
                <Route exact path="/demo(.html)?" component={Demo} />
                <Route exact path="/faq(.html)?" component={FAQ} />
                <Route exact path="/features(.html)?" component={Features} />
                <Route exact path="/pricing_configurator(.html|.php)?" component={PricingConfigurator} />
                <Route exact path={`/paymentinit(.html|.php)?/:orderNumber/:paymentReferenceNumber`} component={PaymentInit} />
                <Route exact path={PaymentCallbackSuccessUrl} component={PaymentCalback} />
                <Route render={() => <Redirect to="/404" />} />
              </Switch>
            </DefaultLayout>
          )} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
