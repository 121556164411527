import { getLocalizedText, getCurrentLocale } from './localizationManager';

const allLanguages = [
    { code: 'tr', name: getLocalizedText('Turkish'), flagCode: 'tr', showAsAppLang: true },
    { code: 'en', name: getLocalizedText('English'), flagCode: 'us', showAsAppLang: true },
    { code: 'de', name: getLocalizedText('German'), flagCode: 'de', showAsAppLang: true },
    { code: 'af', name: 'Afrikaans', flagCode: 'za' },
    { code: 'ae', name: 'Arabic/RTL'/* 'Arabic (U.A.E)' */, flagCode: 'ae', showAsAppLang: true, isRTL: true },
    // { code: 'bh', name: 'Bahrain', flagCode: 'bh' },
    // { code: 'dz', name: 'Algeria', flagCode: 'dz' },
    // { code: 'eg', name: 'Egypt', flagCode: 'eg' },
    // { code: 'iq', name: 'Iraq', flagCode: 'iq' },
    // { code: 'jo', name: 'Jordan', flagCode: 'jo' },
    // { code: 'kw', name: 'Kuwait', flagCode: 'kw' },
    // { code: 'lb', name: 'Lebanon', flagCode: 'lb' },
    // { code: 'ly', name: 'Libya', flagCode: 'ly' },
    // { code: 'ma', name: 'Morocco', flagCode: 'ma' },
    // { code: 'om', name: 'Oman', flagCode: 'om' },
    // { code: 'qa', name: 'Qatar', flagCode: 'qa' },
    { code: 'sa', name: 'Arabic (Saudi Arabia)', flagCode: 'sa' },
    // { code: 'sy', name: 'Syria', flagCode: 'sy' },
    // { code: 'tn', name: 'Arabic (Tunisia)', flagCode: 'tn' },
    // { code: 'ye', name: 'Arabic (Yemen)', flagCode: 'ye' },
    { code: 'az', name: 'Azeri', flagCode: 'az' },
    { code: 'by', name: 'Belarusian', flagCode: 'by' },
    { code: 'bg', name: 'Bulgarian', flagCode: 'bg' },
    { code: 'ba', name: 'Bosnian', flagCode: 'ba' },
    { code: 'es', name: 'Spanish', flagCode: 'es' },
    { code: 'cz', name: 'Czech', flagCode: 'cz' },
    { code: 'gb', name: 'Welsh', flagCode: 'gb' },
    { code: 'dk', name: 'Danish', flagCode: 'dk' },
    // { code: 'at', name: 'Austria', flagCode: 'at' },
    // { code: 'ch', name: 'Switzerland', flagCode: 'ch' },
    // { code: 'li', name: 'Liechtenstein', flagCode: 'li' },
    // { code: 'lu', name: 'Luxembourg', flagCode: 'lu' },
    { code: 'mv', name: 'Divehi', flagCode: 'mv' },
    { code: 'gr', name: 'Greek', flagCode: 'gr' },
    // { code: 'au', name: 'Australia', flagCode: 'au' },
    // { code: 'bz', name: 'Belize', flagCode: 'bz' },
    // { code: 'ca', name: 'Canada', flagCode: 'ca' },
    // { code: 'cb', name: 'Caribbean', flagCode: 'cb' },
    // { code: 'ie', name: 'Ireland', flagCode: 'ie' },
    // { code: 'jm', name: 'Jamaica', flagCode: 'jm' },
    // { code: 'nz', name: 'New Zealand', flagCode: 'nz' },
    // { code: 'ph', name: 'Republic of the Philippines', flagCode: 'ph' },
    // { code: 'tt', name: 'Trinidad and Tobago', flagCode: 'tt' },
    // { code: 'zw', name: 'Zimbabwe', flagCode: 'zw' },
    // { code: 'ar', name: 'Argentina', flagCode: 'ar' },
    // { code: 'bo', name: 'Bolivia', flagCode: 'bo' },
    // { code: 'cl', name: 'Chile', flagCode: 'cl' },
    // { code: 'co', name: 'Colombia', flagCode: 'co' },
    // { code: 'cr', name: 'Costa Rica', flagCode: 'cr' },
    // { code: 'do', name: 'Dominican Republic', flagCode: 'do' },
    // { code: 'ec', name: 'Ecuador', flagCode: 'ec' },
    // { code: 'gt', name: 'Guatemala', flagCode: 'gt' },
    // { code: 'hn', name: 'Honduras', flagCode: 'hn' },
    // { code: 'mx', name: 'Mexico', flagCode: 'mx' },
    // { code: 'ni', name: 'Nicaragua', flagCode: 'ni' },
    // { code: 'pa', name: 'Panama', flagCode: 'pa' },
    // { code: 'pe', name: 'SPansiPeru', flagCode: 'pe' },
    // { code: 'pr', name: 'Spanish (Puerto Rico)', flagCode: 'pr' },
    // { code: 'py', name: 'Spanish (Paraguay)', flagCode: 'py' },
    // { code: 'uy', name: 'Spanish (Uruguay)', flagCode: 'uy' },
    // { code: 've', name: 'Spanish (Venezuela)', flagCode: 've' },
    { code: 'ee', name: 'Estonian', flagCode: 'ee' },
    { code: 'ir', name: 'Farsi', flagCode: 'ir' },
    { code: 'fi', name: 'Finnish', flagCode: 'fi' },
    { code: 'fo', name: 'Faroese', flagCode: 'fo' },
    { code: 'be', name: 'French (Belgium)', flagCode: 'be' },
    { code: 'fr', name: 'French', flagCode: 'fr' },
    { code: 'in', name: 'Hindi', flagCode: 'in' },
    { code: 'hr', name: 'Croatian', flagCode: 'hr' },
    { code: 'hu', name: 'Hungarian', flagCode: 'hu' },
    { code: 'am', name: 'Armenian', flagCode: 'am' },
    { code: 'id', name: 'Indonesian', flagCode: 'id' },
    { code: 'is', name: 'Icelandic', flagCode: 'is' },
    { code: 'it', name: 'Italian', flagCode: 'it' },
    { code: 'jp', name: 'Japanese', flagCode: 'jp' },
    { code: 'ge', name: 'Georgian', flagCode: 'ge' },
    { code: 'kz', name: 'Kazakh', flagCode: 'kz' },
    { code: 'kg', name: 'Kyrgyz', flagCode: 'kg' },
    { code: 'lt', name: 'Lithuanian', flagCode: 'lt' },
    { code: 'lv', name: 'Latvian', flagCode: 'lv' },
    { code: 'mk', name: 'FYRO Macedonian', flagCode: 'mk' },
    { code: 'mn', name: 'Mongolia', flagCode: 'mn' },
    { code: 'my', name: 'Malay', flagCode: 'my' },
    { code: 'mt', name: 'Maltese', flagCode: 'mt' },
    { code: 'no', name: 'Norwegian', flagCode: 'no' },
    { code: 'nl', name: 'Dutch', flagCode: 'nl' },
    { code: 'pl', name: 'Polish', flagCode: 'pl' },
    { code: 'br', name: 'Portuguese', flagCode: 'br' },
    { code: 'pt', name: 'Portuguese', flagCode: 'pt' },
    { code: 'ro', name: 'Romanian', flagCode: 'ro' },
    { code: 'ru', name: 'Russian', flagCode: 'ru' },
    { code: 'se', name: 'Sami', flagCode: 'se' },
    { code: 'sk', name: 'Slovak', flagCode: 'sk' },
    { code: 'si', name: 'Slovenian', flagCode: 'si' },
    { code: 'al', name: 'Albanian', flagCode: 'al' },
    { code: 'sp', name: 'Serbian', flagCode: 'sp' },
    { code: 'ke', name: 'Swahili', flagCode: 'ke' },
    { code: 'th', name: 'Thai', flagCode: 'th' },
    { code: 'ua', name: 'Ukrainian', flagCode: 'ua' },
    { code: 'pk', name: 'Urdu', flagCode: 'pk' },
    { code: 'uz', name: 'Uzbek', flagCode: 'uz' },
    { code: 'vn', name: 'Vietnamese', flagCode: 'vn' },
    { code: 'cn', name: 'Chinese (S)', flagCode: 'cn' },
    { code: 'hk', name: 'Chinese (Hong Kong)', flagCode: 'hk' },
    { code: 'mo', name: 'Chinese (Macau)', flagCode: 'mo' },
    { code: 'sg', name: 'Chinese (Singapore)', flagCode: 'sg' },
    { code: 'tw', name: 'Chinese (T)', flagCode: 'tw' }
];

const appLanguages = allLanguages.filter(lang => lang.showAsAppLang);

function getCurrentLang() {
    var locale = getCurrentLocale().toLowerCase();
    var lang = allLanguages.find(lang => lang.code == locale);

    return lang ? lang.flagCode : null;
}

export { allLanguages, appLanguages, getCurrentLang };